import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Home from '../components/Home'
import Vendas from '../modules/Vendas/Vendas'
import Compras from '../modules/Compras/Compras'
import AprovacaoDescontoOsOrcamento from '../modules/AprovacaoDescontoOsOrcamento/Index'
import AprovacaoPedidoCompra from '../modules/AprovacaoPedidoCompra/AprovacaoPedidoCompra'
import DetalheOrcamento from '../modules/AprovacaoDescontoOsOrcamento/DetalheOrcamento/DetalheOrcamento'

const RoutesAuthenticated = () => {

  return (
    <Routes>
      {/* <Route path="/login" element={<Login />} /> */}
      {/* <Route path="*" element={<Navigate to="/home" />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/vendas" element={<Vendas />} />
      <Route path="/compras" element={<Compras />} />
      <Route path="/compras" element={<Compras />} />
      <Route path="/aprovacao_desconto_os_orcamento" element={<AprovacaoDescontoOsOrcamento />} />
      <Route path="/aprovacao_desconto_os_orcamento_detalhe" element={<DetalheOrcamento />} />
      <Route path="/aprovacao_pedido_compra" element={<AprovacaoPedidoCompra />} />

      <Route path="*" element={<Navigate to="/" />} />




    </Routes>
  )

}

export default RoutesAuthenticated
