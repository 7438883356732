import React from 'react';
import { MainContainer } from '../../components/template/MainContainer';

function Vendas() {
  return (
    <MainContainer>
      <h1>Vendas</h1>
    </MainContainer>
  );
}

export default Vendas;
