import React from 'react'
import { MainHeader } from './Header'
import Footer from './Footer'

export const MainContainer = ({ children, showHeader = true, headerData}) => {

    const headerComponent = showHeader ? <MainHeader headerData={headerData} /> : null;
    const containerClass = showHeader ? 'main-container mt-5' : 'flex-fill mt-0';

    return (
        <>
            {headerComponent}
            <div className={`${containerClass}`}>
                {children}
            </div>
            <Footer />
        </>
    );
}