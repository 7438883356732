import React, { useState, useRef, useCallback } from 'react';
import './Login.css';
import { Logo } from './template/Logo';
import { useAuth } from '../context/AuthContext';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await signIn(username, password);
    setLoading(false);

    if (!result.isOk) {
      setErrorText(result.message);
      console.error(result.message);
    }
  }, [username, password, signIn]);

  return (
    <div className="container-fluid p-0 d-flex flex-column justify-content-center align-items-center min-vh-100 text-center">
      <Logo />

      <div className="login-box text-center p-2">
        <form onSubmit={onSubmit}>
          <input
            type="text"
            className="form-control custom-input mb-1"
            id="username"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            className="form-control custom-input mb-3"
            id="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="submit"
            className="btn btn-success-custom w-100 fw-bold themeBGColorSecondary themeTextColorPrimary"
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Acessar'}
          </button>
          {errorText && <span className="error-text">{errorText}</span>}
        </form>
      </div>
    </div>
  );
}

export default Login;
