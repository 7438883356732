import React from 'react';
import { MainContainer } from '../../components/template/MainContainer';
import CabecalhoBotaoOrcamento from './CabecalhoBotaoOrcamento';

const data = [
  { "title": "Orçamento 001", "companyInfo": "004475/01 - DAMHA AGRONEGOCIOS LTDA", "orderInfo": "ORC.: 00387968", "dateInfo": "11/04/2024 - 13:02", "idInfo": "Nro Ident.: 00018306", "contactPerson": "ZAQUEU FERREIRA" },
  { "title": "Orçamento 002", "companyInfo": "004476/02 - ABC AGRICULTURAL", "orderInfo": "ORC.: 00387969", "dateInfo": "12/04/2024 - 14:15", "idInfo": "Nro Ident.: 00018307", "contactPerson": "JOÃO SILVA" },
  { "title": "Orçamento 003", "companyInfo": "004477/03 - XYZ FARMING CO", "orderInfo": "ORC.: 00387970", "dateInfo": "13/04/2024 - 15:30", "idInfo": "Nro Ident.: 00018308", "contactPerson": "MARIA SOUZA" },
  { "title": "Orçamento 004", "companyInfo": "004478/04 - GREENFIELD INC.", "orderInfo": "ORC.: 00387971", "dateInfo": "14/04/2024 - 16:45", "idInfo": "Nro Ident.: 00018309", "contactPerson": "CARLOS ALMEIDA" },
  { "title": "Orçamento 005", "companyInfo": "004479/05 - AGRI TECH SOLUTIONS AGRI TECH SOLUTIONS ", "orderInfo": "ORC.: 00387972", "dateInfo": "15/04/2024 - 18:00", "idInfo": "Nro Ident.: 00018310", "contactPerson": "FERNANDA COSTA" }
]


export default () => (
  <MainContainer headerData={{title: `Autorização Venda`, subtitle: `Liberação`}}>
    <CabecalhoBotaoOrcamento data={data[1]} />
    <CabecalhoBotaoOrcamento data={data[2]} />
    <CabecalhoBotaoOrcamento data={data[3]} />
    <CabecalhoBotaoOrcamento data={data[4]} />
    
  </MainContainer>
);