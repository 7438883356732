import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider, useAuth } from '../context/AuthContext'
import RoutesAuthenticated from './RoutesAuthenticated'
import RoutesUnauthenticated from './RoutesUnauthenticated'
import '../assets/styles/App.css'

const LoadPanel = () => {
  return <div>loading....</div>
}

const AppRender = () => {
  const { user, loading } = useAuth();
  // console.log('user')
  // console.log(user)

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <RoutesAuthenticated />;
  }

  return <RoutesUnauthenticated />;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRender />        
      </Router>
    </AuthProvider>
  )
}

export default App