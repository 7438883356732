import React from 'react';
import './DetalheOrcamento.css';
import { MainContainer } from '../../../components/template/MainContainer';
import dadosOrcamento from './dadosOrcamento.json';
import DetalheOrcamentoItens from './DetalheOrcamentoItens';

const DetalheOrcamento = () => {
    const data = dadosOrcamento;

    return (
        <MainContainer headerData={{ title: `Liberando a Venda`, subtitle: `Descontos` }}>
            <div className="container-fluid detalhe-orcamento-container themeTextColorPrimary mb-2">
                {/* Informações principais */}
                <div className="row mb-2">
                    <div className="col-12">
                        <label>Cliente:</label>
                        <input type="text" className="form-control" value={data.clientInfo} readOnly />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label>Data/Hora:</label>
                        <input type="text" className="form-control" value={data.dateInfo} readOnly />
                    </div>
                    <div className="col-6">
                        <label>Usuário:</label>
                        <input type="text" className="form-control" value={data.userInfo} readOnly />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label>Orçamento:</label>
                        <input type="text" className="form-control" value={data.orderNumber} readOnly />
                    </div>
                    <div className="col-6">
                        <label>Nro Identif.:</label>
                        <input type="text" className="form-control" value={data.idInfo} readOnly />
                    </div>
                </div>
               
                <DetalheOrcamentoItens />

                

                {/* Botões de ação */}
                <div className="row mt-4">
                    <div className="col-6">
                        <button className="btn btn-danger w-100">Rejeitar</button>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-success w-100">Liberar</button>
                    </div>
                </div>
            </div>
        </MainContainer>
    );
};

export default DetalheOrcamento;
