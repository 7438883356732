import React from 'react'

const Footer = () => {
  const userId = 1234
  const userName = 'PAULO PRECHT'

  return (
    <footer className="bg-light">
      <div className="container themeTextColorPrimary fw-bold">
        <p className='m-0 p-0'>D.CARVALHO | JOHN DEERE</p>
        <p className='m-0 p-0'>{userId} - {userName}</p>
      </div>
    </footer>
  )
}

export default Footer
