import React from 'react';
import { useNavigate } from 'react-router-dom';

import './CabecalhoBotaoOrcamento.css';

const CabecalhoBotaoOrcamento = ({ data }) => {
    const navigate = useNavigate();

    const title = data?.title ?? 'Título padrão';
    const companyInfo = data?.companyInfo ?? 'Informação da empresa não disponível';
    const orderInfo = data?.orderInfo ?? 'Informação do pedido não disponível';
    const dateInfo = data?.dateInfo ?? 'Data não disponível';
    const idInfo = data?.idInfo ?? 'Identificação não disponível';
    const contactPerson = data?.contactPerson ?? 'Pessoa de contato não disponível';

    return (
        <div className="container container-box themeTextColorPrimary mb-2" role="button"
        onClick={() =>  navigate("/aprovacao_desconto_os_orcamento_detalhe")}
        >
            
            <div className="row">
                <div className="col-12">
                    <h5 className="fw-bold">{title}</h5>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <p className="custom-text mb-0">{companyInfo}</p>
                    <p className="custom-text mb-0">{orderInfo}</p>
                    <p className="custom-text mb-0">{dateInfo}</p>
                </div>
                <div className="col-4 d-flex flex-column justify-content-end text-end">
                    <p className="custom-text mb-0">{idInfo}</p>
                    <p className="custom-text mb-0">{contactPerson}</p>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <p className="custom-text mb-0">{companyInfo}</p>
                    <p className="custom-text mb-0">{orderInfo}</p>
                    <p className="custom-text mb-0">{dateInfo}</p>
                </div>
                <div className="col-4 d-flex flex-column justify-content-end text-end">
                    <p className="custom-text mb-0">{idInfo}</p>
                    <p className="custom-text mb-0">{contactPerson}</p>
                </div>
            </div>            
        </div>
    );
};

export default CabecalhoBotaoOrcamento;
