import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './Logo.css';

const BtnSair = props => {
    const { signOut } = useAuth();
    const navigate = useNavigate();

    const handleLogout = (event) => {
        event.preventDefault();

        signOut();
        navigate('/login');
    };

    return (
        <div className="w-100 text-end px-2">
            <Link to="/login" onClick={handleLogout} className="text-decoration-none themeTextColorPrimary fw-bold">Sair</Link>
        </div>
    );
};

export const Logo = ({ showLogout = false }) => {
    const h2Ref = useRef(null);
    const logoContainerRef = useRef(null);

    useEffect(() => {
        const adjustLogoWidth = () => {
            if (h2Ref.current && logoContainerRef.current) {
                logoContainerRef.current.style.width = `${h2Ref.current.offsetWidth}px`;
            }
        };

        // Ajusta a largura do logo quando o componente é montado
        adjustLogoWidth();

        // Ajusta a largura do logo quando a janela é redimensionada
        window.addEventListener('resize', adjustLogoWidth);

        // Remove o listener quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', adjustLogoWidth);
        };
    }, []);

    return (
        <div className='container-fluid p-0 d-flex flex-column align-items-center text-center'>
            {showLogout && <BtnSair />}
            <h2 ref={h2Ref} className="mb-3 fw-bold">D. Carvalho</h2>
            <div ref={logoContainerRef} className="logo-container mb-3">
                <img src="/assets/images/logo.png" className="logo" alt="Logo" />
            </div>
            <div className="line-green w-100"></div>
            <div className="line-yellow w-100 mb-3"></div>
        </div>
    );
};

export default Logo;
