import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../components/Login';

const RoutesUnauthenticated = () => {
  
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/" element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )

}

export default RoutesUnauthenticated