import React from 'react';
import './DetalheOrcamentoItens.css';
import dadosOrcamento from './dadosOrcamento.json';

const DetalheOrcamentoItens = () => {
    const data = dadosOrcamento;

    return <>
        <div className="section-header fw-bold h6 mt-4 themeBGColorPrimary themeTextColorSecondary borderRadius2">Peças:</div>

        <div className="items-container">
            {data.items.map((item, index) => (
                <div key={index} className="item-box mb-2 p-2">
                    <div className="d-flex justify-content-between align-items-center mb-2 item-header">
                        <strong>{item.code} - {item.description}</strong>
                        <strong>{item.price}</strong>
                    </div>
                    <div className="row text-center">
                        <div className="col-4">
                            <p>Des.Permit.</p>
                            <p>{item.desPermit}</p>
                        </div>
                        <div className="col-4">
                            <p>Des.Desej.</p>
                            <p>{item.desDesej}</p>
                        </div>
                        <div className="col-4">
                            <p>Marg.Lucro</p>
                            <p>{item.margLucro}</p>
                        </div>
                    </div>

                    <div className="row text-center">
                        <div className="col-4">
                            <p>Val.Orig.</p>
                            <p>{item.valOrig}</p>
                        </div>
                        <div className="col-4">
                            <p>Val.Permit.</p>
                            <p>{item.valPermit}</p>
                        </div>
                        <div className="col-4">
                            <p>Val.Desej.</p>
                            <p>{item.valDesej}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </>

};

export default DetalheOrcamentoItens;
